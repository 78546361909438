import { useMap } from 'app/providers/MapProvider';
import mapboxgl from 'mapbox-gl';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCampaign } from 'store/reducers/campaignSlice';
import { LayersName, host } from '../utils';

export const useOpenreach = () => {
    const { map } = useMap();
    const { uuidForMap: campaignId } = useSelector(selectCampaign);

    const popup = new mapboxgl.Popup({
        closeButton: false,
    });

    useEffect(() => {
        if (!map || !campaignId) return;

        if (map.getSource('openreach-properties')) {
            map.removeLayer(LayersName.Openreach);
            map.removeSource('openreach-properties');
        }

        // set up source
        map.addSource('openreach-properties', {
            type: 'vector',
            tiles: [
                `${host}/api/v1/campaigns/${campaignId}/openreach-properties/tiles/{z}/{x}/{y}.mvt`,
            ],
            minzoom: 15,
            maxzoom: 24,
        });

        // set up point color for poly
        map.addLayer({
            id: LayersName.Openreach,
            type: 'circle',
            source: 'openreach-properties',
            'source-layer': 'default',
            paint: {
                'circle-color': '#8000FF',
                'circle-opacity': 0.7,
                'circle-radius': {
                    'base': 1.75,
                    'stops': [
                        [12, 2],
                        [22, 90]
                    ]
                },
                'circle-stroke-width': 1,
                'circle-stroke-color': 'black'
            },
            minzoom: 15,
            maxzoom: 24,
        });

        // click on subpolygon
        map.on('click', LayersName.Openreach, (e) => {
            // @ts-ignore
            const props = e.features[0].properties!;

            const uprn = props.uprn;
            const mdu = props.mdu;
            const parent_exchange_name = props.parent_exchange_name;
            const child_exchange_name = props.child_exchange_name;
            const prem_served = props.prem_served;
            const date_taken = props.date_taken;
            const category = props.category;
            const speeds = props.speeds;
            const interconnected_date = props.interconnected_date;

            const titleHtml = `<h1>UPRN: <strong>${uprn}</strong></h1>`;
            const propsHtml = `
                <h2>MDU: <strong> ${mdu}</strong></h2>
                <h2>Parent exchange name: <strong>${parent_exchange_name}</strong></h2>
                <h2>Child exchange name: <strong>${child_exchange_name}</strong></h2>
                <h2>Prem served: <strong>${prem_served}</strong></h2>
                <h2>Date taken: <strong>${date_taken}</strong></h2>
                <h2>Category: <strong>${category}</strong></h2>
                <h2>Speeds: <strong>${speeds}</strong></h2>
                <h2>Interconnected_date:<strong> ${interconnected_date}</strong></h2>
              `;
            const html = `<div class='popup'>
                    ${titleHtml}
                    ${propsHtml}
                </div>`;

            popup.setLngLat(e.lngLat)
                .setHTML(html)
                .addTo(map);
        });

        map.on('mouseenter', LayersName.Openreach, () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', LayersName.Openreach, () => {
            map.getCanvas().style.cursor = '';
        });
    }, [map, campaignId]);
};