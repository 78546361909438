import { useMap } from 'app/providers/MapProvider';
import { format } from 'date-fns';
import mapboxgl from 'mapbox-gl';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCampaign } from 'store/reducers/campaignSlice';
import { LayersName, host } from '../utils';

export const useCityFibrePoints = () => {
    const { map } = useMap();
    const { uuidForMap: campaignId } = useSelector(selectCampaign);

    const popup = new mapboxgl.Popup({
        closeButton: false,
    });

    useEffect(() => {
        if (!map || !campaignId) return;

        if (map.getSource('city-fibre-uprns')) {
            map.removeLayer(LayersName.CityFibre);
            map.removeSource('city-fibre-uprns');
        }

        // set up source
        map.addSource('city-fibre-uprns', {
            type: 'vector',
            tiles: [
                `${host}/api/v1/campaigns/${campaignId}/city-fibre-properties/tiles/{z}/{x}/{y}.mvt`,
            ],
            minzoom: 15,
            maxzoom: 24,
        });

        // set up point color for poly
        map.addLayer({
            id: LayersName.CityFibre,
            type: 'circle',
            source: 'city-fibre-uprns',
            'source-layer': 'default',
            paint: {
                'circle-color': '#555555',
                'circle-opacity': 0.8,
                'circle-radius': {
                    'base': 1.9,
                    'stops': [
                        [12, 2],
                        [22, 90]
                    ]
                },
                'circle-stroke-width': 1,
                'circle-stroke-color': 'black'
            },
            minzoom: 15,
            maxzoom: 24,
        });

        // click on subpolygon
        map.on('click', LayersName.CityFibre, (e) => {
            // @ts-ignore
            const props = e.features[0].properties;

            const uprn = props?.uprn;
            const rfs_date = props?.rfs_date;
            const demand_point_type = props?.demand_point_type;

            const titleHtml = `<h1>UPRN: <strong>${uprn}</strong></h1>`;
            const propsHtml = `
                <h2>RFS date: <strong>${format(new Date(rfs_date), 'dd.MM.yyyy')}</strong></h2>
                <h2>Demand point type:<strong> ${demand_point_type}</strong></h2>
              `;
            const html = `<div class='popup'>
                  ${titleHtml}
                  ${propsHtml}
              </div>`;

            popup.setLngLat(e.lngLat)
                .setHTML(html)
                .addTo(map);
        });

        map.on('mouseenter', LayersName.CityFibre, () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', LayersName.CityFibre, () => {
            map.getCanvas().style.cursor = '';
        });
    }, [map, campaignId]);
};