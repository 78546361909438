import { Table, Tag } from 'antd';
import classNames from 'classnames';
import { WorkersSelect } from 'components/WorkersSelect';
import { NotificationType, useNotification } from 'hooks/useNotification';
import { CampaignRun } from 'models/Campaigns';
import { useState } from 'react';
import { campaignRunAPI } from 'services/Campaigns/CampaignsRunService';
import { runNameFormatter } from 'utils/runNameFormatter';
import { RunStatus } from '../types';

export const CampaignRuns = ({ list }: { list: CampaignRun[]; }) => {

    const runsInCampaign = list.map((el) => ({ ...el, key: el.uuid }));

    const [runUuid, setRunUuid] = useState<string>('');
    const [expandedRows, setExpandedRows] = useState<string[]>([]);

    const { data: chunks = [] } = campaignRunAPI.useGetChunksInRunQuery({ run_uuid: runUuid }, {
        skip: !runUuid
    });

    const [assignRun, { isSuccess, isError, isLoading: isAssignRunLoading, }] = campaignRunAPI.useAssignRunToWorkerMutation();

    useNotification({ type: NotificationType.SUCCESS, trigger: isSuccess, text: 'Worker successfully assigned!' });
    useNotification({ type: NotificationType.ERROR, trigger: isError, text: 'An error occurred while assigning worker. If the issue persists, contact support for assistance.' });

    const columns = [
        {
            title: 'Name', dataIndex: 'name',
            render: (name: string) => {
                return runNameFormatter(name);
            }
        },
        { title: 'Chunk count', dataIndex: 'chunk_count', },
        {
            title: 'Active', dataIndex: 'is_active',
            render: (is_active: any) => is_active ? <Tag color='green'>Active</Tag> : <Tag color='red'>Inactive</Tag>,

        },
        {
            title: 'Status', dataIndex: 'status', render: (status: any) => {

                switch (status) {
                    case RunStatus.SUCCESS:
                        return <Tag color='green'>{RunStatus.SUCCESS}</Tag>;
                    case RunStatus.FAILED:
                        return <Tag color='red'>{RunStatus.FAILED}</Tag>;
                    case RunStatus.RUNNING:
                        return <Tag color='yellow'>{RunStatus.RUNNING}</Tag>;
                    case RunStatus.PENDING:
                        return <Tag color='blue'>{RunStatus.PENDING}</Tag>;
                }
            },
        },
    ];

    const expandedRowRender = (rowData: any) => {
        const { uuid } = rowData;

        const columns = [
            { title: 'Chunk №', dataIndex: 'chunk_id', },
            { title: 'Property per person', dataIndex: 'uprn_count', },
            { title: 'Assigned worker', dataIndex: 'worker_uuid', },
        ];

        const data = chunks.map((chunk) => {
            const { chunk_id, uprn_count, worker_uuid } = chunk;

            return {
                key: chunk_id,
                chunk_id,
                uprn_count,
                worker_uuid: <WorkersSelect value={worker_uuid} setValue={async (data: any) => {
                    try {
                        await assignRun({
                            run_uuid: uuid,
                            chunk_id,
                            worker_uuid: data
                        });
                        // refetch();
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.log('Updating creating user:', error);
                    }
                }} isLoading={isAssignRunLoading} />,
            };
        });
        return <Table columns={columns} dataSource={data} pagination={false} />;
    };

    return <>
        <h2 className={classNames('text-lg', 'text-500', 'text-primary')}>Campaign runs</h2>
        <Table
            columns={columns}
            pagination={false}
            dataSource={runsInCampaign}
            expandable={{
                expandedRowRender,
                onExpand: (isRowExpanded, record) => {
                    if (isRowExpanded === true) {
                        setRunUuid(record.uuid);
                        setExpandedRows([record.uuid]);
                    } else {
                        setRunUuid('');
                        setExpandedRows([]);
                    }
                },
                expandedRowKeys: expandedRows,
                rowExpandable: (value) => {
                    if (value.status === RunStatus.FAILED || value.status === RunStatus.PENDING || value.status === RunStatus.RUNNING) return false;
                    return true;
                }
            }}
        /></>;
};