import { Table, Tag } from 'antd';
import classNames from 'classnames';
import { DigitalMarketingRun } from 'models/Campaigns';
import { runNameFormatter } from 'utils/runNameFormatter';
import { RunStatus } from '../types';

export const DigitalMarketingRuns = ({ list }: { list: DigitalMarketingRun[]; }) => {
    const runsInCampaign = list.map((el) => ({ ...el, key: el.uuid }));

    const columns = [
        {
            title: 'Name', dataIndex: 'name',
            render: (name: any) => runNameFormatter(name)
        },
        { title: 'Property count', dataIndex: 'max_property_count', },
        {
            title: 'Active', dataIndex: 'is_active',
            render: (is_active: any) => is_active ? <Tag color='green'>Active</Tag> : <Tag color='red'>Inactive</Tag>,

        },
        {
            title: 'Status', dataIndex: 'status', render: (status: any) => {

                switch (status) {
                    case RunStatus.SUCCESS:
                        return <Tag color='green'>{RunStatus.SUCCESS}</Tag>;
                    case RunStatus.FAILED:
                        return <Tag color='red'>{RunStatus.FAILED}</Tag>;
                    case RunStatus.RUNNING:
                        return <Tag color='yellow'>{RunStatus.RUNNING}</Tag>;
                    case RunStatus.PENDING:
                        return <Tag color='blue'>{RunStatus.PENDING}</Tag>;
                }
            },
        },
    ];

    return <>
        <h2 className={classNames('text-lg', 'text-500', 'text-primary')}>Digital marketing runs</h2>

        <Table
            columns={columns}
            pagination={false}
            dataSource={runsInCampaign}
        />
    </>;
};