export const host = process.env.REACT_APP_HERMES_API_URL;

export const floatToPercent = (x: any) => `${(x * 100).toFixed(3)}%`;

export enum Colors {
    Red = '#FF0000',
    Orange = '#FF7F00',
    Yellow = '#FFFF00',
    YellowGreen = '#7FFF00',
    Green = '#00FF00',
    White = '#FFFFFF',
    Black = '#000000',
    Gray = '#727272',
    Transparent = 'transparent'
}

export const colorRule = [
    'case',
    ['<', ['get', 'predicated_pen'], 0.45],
    Colors.Red,
    ['all', ['>=', ['get', 'predicated_pen'], 0.45], ['<', ['get', 'predicated_pen'], 0.5]],
    Colors.Orange,
    ['all', ['>=', ['get', 'predicated_pen'], 0.5], ['<', ['get', 'predicated_pen'], 0.6]],
    Colors.Yellow,
    ['all', ['>=', ['get', 'predicated_pen'], 0.6], ['<', ['get', 'predicated_pen'], 0.7]],
    Colors.YellowGreen,
    ['>=', ['get', 'predicated_pen'], 0.7],
    Colors.Green,
    Colors.Transparent
];

export enum LayersName {
    CityFibre = 'city-fibre-uprns-layer',
    Streetworks = 'streetworks-layer',
    Openreach = 'openreach-properties-layer',
    PenModelPolygonFill = 'oa-fill-layer',
    PenModelPolygonLine = 'oa-line-layer',
    Properties = 'property-circle-layer'
}
