import { useEffect } from 'react';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export enum NotificationType {
    SUCCESS = 'success',
    ERROR = 'error',
}

interface Props {
    type: NotificationType;
    trigger: boolean;
    text?: string;
}

export const useNotification = ({
    type = NotificationType.SUCCESS,
    trigger = true,
    text = 'Action completed!',
}: Props) => {
    useEffect(() => {
        if (trigger) {
            toast[type](text, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [trigger]);
};
