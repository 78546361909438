import { yupResolver } from '@hookform/resolvers/yup';
import { Switch } from 'antd';
import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { Input } from 'elements/Input';
import { NotificationType, useNotification } from 'hooks/useNotification';
import { CampaignsFullExport } from 'models/Campaigns';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { campaignDigitalMarketingRunAPI } from 'services/Campaigns/CampaignsDigitalMarketingRunService';
import { campaignMarketingRunAPI } from 'services/Campaigns/CampaignsMarketingRunService';
import { campaignAPI } from 'services/Campaigns/CampaignsService';
import { selectCampaign } from 'store/reducers/campaignSlice';
import { MarketingInputs, defaultValuesMarketing, validationSchemaMarketing } from '../FormValidation';
import styles from './styles.module.scss';

export const MarketingForm = () => {
    const { data: campaigns = [] as CampaignsFullExport } = campaignAPI.useGetAllCampaignsQuery();
    const { uuidForExport: uuid } = useSelector(selectCampaign);
    const campaign = campaigns.find((el) => el.uuid === uuid);

    const [isDigitalMarketing, setDigitalMarketing] = useState(false);
    const [createMarketingExport, { isLoading: isMarketingLoading, isSuccess: isMarketingSuccess, isError: isMarketingError }] = campaignMarketingRunAPI.useCreateCampaignMarketingRunExportMutation();
    const [createDigitalMarketingExport, { isLoading: isDigitalMarketingLoading, isSuccess: isDigitalMarketingSuccess, isError: isDigitalMarketingError }] = campaignDigitalMarketingRunAPI.useCreateCampaignDigitalMarketingRunExportMutation();

    const onChange = (checked: boolean) => {
        setDigitalMarketing(checked);
    };

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: defaultValuesMarketing,
        resolver: yupResolver(validationSchemaMarketing),
    });

    const onSubmit = async (data: {
        max_property_count: number,
    }) => {
        if (!campaign || !uuid) return;

        try {
            if (isDigitalMarketing) {
                await createDigitalMarketingExport({
                    body: {
                        campaign_uuid: campaign?.uuid,
                        name: `${campaign?.name}_${Date.now()}`,
                        max_property_count: data.max_property_count,
                    }
                });
            } else {
                await createMarketingExport({
                    body: {
                        campaign_uuid: campaign?.uuid,
                        name: `${campaign?.name}_${Date.now()}`,
                        max_property_count: data.max_property_count,
                    }
                });
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Error creating user:', error);
        }
    };

    // marketing
    useNotification({ type: NotificationType.SUCCESS, trigger: isMarketingSuccess, text: 'Marketing email export request for selected campaign has been queued!' });
    useNotification({ type: NotificationType.ERROR, trigger: isMarketingError, text: 'An error occurred while generating marketing export. If the issue persists, contact support for assistance.' });

    // digital marketing
    useNotification({ type: NotificationType.SUCCESS, trigger: isDigitalMarketingSuccess, text: 'Digital marketing email export request for selected campaign has been queued!' });
    useNotification({ type: NotificationType.ERROR, trigger: isDigitalMarketingError, text: 'An error occurred while generating digital marketing export. If the issue persists, contact support for assistance.' });

    watch(MarketingInputs.max_property_count);

    return <div className={styles.knockListFormGrid}>

        <Input
            type='number'
            label='Number of Addresses'
            placeholder='Type a number here'
            error={{
                message: errors.max_property_count?.message
            }}
            registerProps={
                { ...register(MarketingInputs.max_property_count, { required: true }) }
            }
        />

        <div className={classNames(styles.switch, styles.switchDisabled)}>
            <Switch disabled defaultChecked onChange={() => { }} />
            <p className={classNames('text-md', 'text-500', 'text-secondary')}>Include MDUs&#39;</p>
            <span className={classNames('text-md', 'text-400', 'text-tertiary', styles.definition)}>A commercial or residential building with multiple offices or apartments</span>
        </div>

        <div className={classNames(styles.switch, styles.switchDisabled)}>
            <Switch disabled onChange={() => { }} />
            <p className={classNames('text-md', 'text-500', 'text-secondary')}>Include Businesses</p>
            <span className={classNames('text-md', 'text-400', 'text-tertiary', styles.definition)}>A commercial building</span>
        </div>

        <div className={styles.switch}>
            <Switch value={isDigitalMarketing} onChange={onChange} />
            <p className={classNames('text-md', 'text-500', 'text-secondary')}>Digital marketing</p>
        </div>

        <Button
            size={ButtonSize.MD}
            palette={ButtonPalette.PRIMARY}
            isLoading={isMarketingLoading || isDigitalMarketingLoading}
            onClick={handleSubmit(onSubmit)}>Create export</Button>

    </div>;
};