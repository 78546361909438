import { Table } from 'antd';
import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { ExpandedRowContent } from 'components/ExpandedRowContent/ExpandedRowContent';
import { format } from 'date-fns';
import { Dropdown } from 'elements/Dropdown';
import { Icons } from 'icons';
import { RegionExport } from 'models/Regions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { regionsAPI } from 'services/Regions/RegionsService';
import { addUuidForEdit, addUuidForExport, addUuidForMap, selectCampaign } from 'store/reducers/campaignSlice';
import { openDrawer } from 'store/reducers/drawerSlice';
import { selectRegion } from 'store/reducers/regionSlice';
import styles from './styles.module.scss';
import { useDeleteCampaign } from './useDeleteCampaign';

export const CampaignSection = () => {
    const regionUuid = useSelector(selectRegion);
    const dispatch = useDispatch();
    const { param1 } = useParams();

    const { data: region = {} as RegionExport, refetch } = regionsAPI.useGetRegionQuery(regionUuid.uuid || param1);
    const { handler } = useDeleteCampaign({ refetch });

    const [activeSelectButton, setActiveSelectButton] = useState<string>('');
    const [activeOnMapButton, setActiveOnMapButton] = useState<string>('');
    const [expandedRow, setExpandedRow] = useState<string[]>([]);
    const { uuidForMap: campaignId } = useSelector(selectCampaign);

    useEffect(() => {
        if (region.campaigns?.length && region.campaigns[0].uuid) {
            dispatch(addUuidForMap({ uuid: region.campaigns[0].uuid }));
        }
    }, [region]);

    const columns: any = [
        Table.EXPAND_COLUMN,
        {
            key: 'campaign_name',
            title: 'Campaign Name',
            dataIndex: 'name',
        },
        {
            key: 'updated',
            title: 'Updated',
            dataIndex: 'updated_at',
            render: (updated_at: string) => format(new Date(updated_at), 'dd.MM.yyyy'),

        },
        {
            key: 'map_actions',
            title: '',
            dataIndex: 'uuid',
            // fixed: 'right',
            width: 260,
            render: (_: any, record: { uuid: string; }) => {
                const { uuid } = record;
                const selectBtnCondition = activeSelectButton === uuid;
                const onMapBtnCondition = activeOnMapButton === uuid || campaignId === uuid;

                return <div className={styles.buttonRow}>
                    <Button
                        size={ButtonSize.MD}
                        palette={onMapBtnCondition ? ButtonPalette.TERTIARY_GRAY : ButtonPalette.SECONDARY_GRAY}
                        onClick={() => {
                            dispatch(addUuidForMap({ uuid }));
                            setActiveOnMapButton(uuid);
                        }}>
                        {onMapBtnCondition ? 'On map' : 'View on map'}
                    </Button>
                    <Button
                        size={ButtonSize.MD}
                        palette={selectBtnCondition ? ButtonPalette.PRIMARY : ButtonPalette.SECONDARY_GRAY}
                        onClick={() => {
                            dispatch(addUuidForExport({ uuid }));
                            dispatch(openDrawer());
                            setActiveSelectButton(uuid);
                        }}>
                        {selectBtnCondition ? 'Selected' : 'Select'}
                    </Button>
                </div>;
            }
        },
        {
            key: 'campaign_actions',
            title: '',
            dataIndex: 'uuid',
            // fixed: 'right',
            width: 30,
            render: (_: any, record: { uuid: string; }) => {
                const { uuid } = record;

                return <Dropdown data={[
                    {
                        content: <><Icons.Edit /> Edit</>,
                        onChange: () => {
                            dispatch(addUuidForEdit({ uuid }));
                        }
                    },
                    {
                        content: <><Icons.Delete /> Delete</>,
                        onChange: () => { handler(uuid); }
                    },
                ]} />;
            }
        }
    ];

    return <>
        <h2 className={classNames('text-primary', 'text-lg', 'text-500', styles.subtitle)}>Campaigns</h2>

        <Table
            columns={columns}
            expandable={{
                expandedRowRender: (record) => {
                    return <ExpandedRowContent record={record} />;
                },
                onExpand: (isRowExpanded, record) => {
                    if (isRowExpanded === true) {
                        setExpandedRow([record.key]);
                    } else {
                        setExpandedRow([]);
                    }
                },
                expandedRowKeys: expandedRow,
            }}
            dataSource={region.campaigns.map((el) => ({ ...el, key: el.uuid }))}
        />
    </>;
};