import { Slider } from 'antd';
import { useMap } from 'app/providers/MapProvider';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { campaignAPI } from 'services/Campaigns/CampaignsService';
import { selectCampaign } from 'store/reducers/campaignSlice';
import { urlFormat } from 'utils/urlFormat';
import styles from '../Map.module.scss';
import { LayersName } from '../utils';

export const PropertiesSlider = () => {
    const { map } = useMap();
    const [sliderValue, setSliderValue] = useState<number[]>([0, 1]);
    const { uuidForMap: campaignId } = useSelector(selectCampaign);

    const { data: countOfProperties = { count: 0 }, refetch } = campaignAPI.useGetCountOfPropertiesQuery({
        campaign_uuid: campaignId, params: urlFormat({
            // min_pen_rate: sliderValue[0],
            // max_pen_rate: sliderValue[1]
        })
    }, {
        skip: !campaignId,
        refetchOnMountOrArgChange: true,
    });

    useEffect(() => {
        if (campaignId) {
            refetch();
        }
    }, [campaignId]);

    useEffect(() => {
        if (!map) return;
        if (map.getLayer(LayersName.Properties)) {
            map.setPaintProperty(LayersName.Properties, 'circle-opacity', [
                'case',
                [
                    'all',
                    ['>=', ['get', 'predicated_pen'], sliderValue[0]],
                    ['<=', ['get', 'predicated_pen'], sliderValue[1]]
                ],
                1,
                0]
            );
            map.setPaintProperty('property-circle-layer', 'circle-stroke-opacity', [
                'case',
                [
                    'all',
                    ['>=', ['get', 'predicated_pen'], sliderValue[0]],
                    ['<=', ['get', 'predicated_pen'], sliderValue[1]]
                ],
                1,
                0]
            );
        }

        if (map.getLayer(LayersName.PenModelPolygonFill)) {
            map.setPaintProperty(LayersName.PenModelPolygonFill, 'fill-opacity', [
                'case',
                [
                    'all',
                    ['>=', ['get', 'predicated_pen'], sliderValue[0]],
                    ['<=', ['get', 'predicated_pen'], sliderValue[1]]
                ],
                0.5,
                0]
            );
            map.setPaintProperty(LayersName.PenModelPolygonLine, 'line-opacity', [
                'case',
                [
                    'all',
                    ['>=', ['get', 'predicated_pen'], sliderValue[0]],
                    ['<=', ['get', 'predicated_pen'], sliderValue[1]]
                ],
                0.5,
                0]
            );
        }

    }, [sliderValue, map]);

    return <>
        <div className={styles.slider}>
            <p className={classNames('text-sm', 'text-400', 'text-primary')}>Predicted pen
                <span className={classNames('text-500', 'text-secondary')}>(Total UPRN count:{countOfProperties.count} )</span>
            </p>
            <Slider range
                defaultValue={sliderValue}
                min={0}
                max={1}
                step={0.01}
                onChange={(el) => {
                    setSliderValue(el);
                }}
            />
        </div>
    </>;
};