import { useMap } from 'app/providers/MapProvider';
import classNames from 'classnames';
import { Checkbox } from 'elements/Checkbox';
import { useEffect, useState } from 'react';
import styles from '../Map.module.scss';
import { LayersName } from '../utils';

export const LayerVisibility = () => {
    const { map } = useMap();

    const [layerVisibility, setLayerVisibility] = useState({
        [LayersName.CityFibre]: true,
        [LayersName.Openreach]: true,
        [LayersName.PenModelPolygonFill]: true,
        [LayersName.PenModelPolygonLine]: true,
        [LayersName.Properties]: true,
        [LayersName.Streetworks]: true,
    });

    useEffect(() => {
        if (!map) return;
        for (const key in layerVisibility) {
            if (!map.getLayer(key)) {
                return;
            }
        }

        for (const key in layerVisibility) {
            if (key === LayersName.Properties) {
                map.setLayoutProperty(LayersName.Properties, 'visibility', layerVisibility[key as LayersName] ? 'visible' : 'none');
                map.setLayoutProperty(LayersName.PenModelPolygonFill, 'visibility', layerVisibility[key as LayersName] ? 'visible' : 'none');
                map.setLayoutProperty(LayersName.PenModelPolygonLine, 'visibility', layerVisibility[key as LayersName] ? 'visible' : 'none');

            } else {
                map.setLayoutProperty(key, 'visibility', layerVisibility[key as LayersName] ? 'visible' : 'none');
            }
        }

    }, [map, layerVisibility]);
    return <>

        <div className={styles.props}>
            <p className={classNames('text-sm', 'text-500', 'text-primary')}>Layers </p>
            <Checkbox text='Sales Propensity Model' isChecked={layerVisibility[LayersName.Properties]} onChange={() => setLayerVisibility(prev => ({
                ...prev,
                [LayersName.Properties]: !prev[LayersName.Properties],
            }))} />
            <Checkbox text='Streetworks' isChecked={layerVisibility[LayersName.Streetworks]} onChange={() => setLayerVisibility(prev => ({
                ...prev,
                [LayersName.Streetworks]: !prev[LayersName.Streetworks]
            }))} />
            <Checkbox text='City Fibre UPRN' isChecked={layerVisibility[LayersName.CityFibre]} onChange={() => setLayerVisibility(prev => ({
                ...prev,
                [LayersName.CityFibre]: !prev[LayersName.CityFibre]
            }))} />
            <Checkbox text='Openreach UPRN' isChecked={layerVisibility[LayersName.Openreach]} onChange={() => setLayerVisibility(prev => ({
                ...prev,
                [LayersName.Openreach]: !prev[LayersName.Openreach]
            }))} />
        </div>
    </>;
};