import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { useState } from 'react';
import { ModalAnalytic } from './ModalAnalytic';
import styles from './styles.module.scss';

export const AnalyticsSection = () => {
    const [isAnalyticsShown, setAnalyticsShown] = useState(false);
    return (<>
        <h2 className={classNames('text-primary', 'text-lg', 'text-500', styles.subtitle)}>Analytics</h2>

        <Button
            size={ButtonSize.MD}
            palette={ButtonPalette.SECONDARY_COLOR}
            onClick={() => setAnalyticsShown(true)}>
            Demographic breakdown
        </Button>

        {isAnalyticsShown && <ModalAnalytic onClose={() => setAnalyticsShown(false)} />}
    </>);
}; 