import { Radio } from 'antd';
import classNames from 'classnames';
import { Drawer } from 'elements/Drawer';
import { CampaignsFullExport } from 'models/Campaigns';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { campaignAPI } from 'services/Campaigns/CampaignsService';
import { selectCampaign } from 'store/reducers/campaignSlice';
import { KnockListForm } from '../Forms/KnockListForm';
import { MarketingForm } from '../Forms/MarketingForm';
import styles from './styles.module.scss';

enum TypeOfExport {
    MARKETING = 'marketing',
    DIGITAL_MARKETING = 'digital_marketing',
    DOOR_TO_DOOR = 'door_to_door'
}

export const ExportDrawer = () => {
    const { data: campaigns = [] as CampaignsFullExport } = campaignAPI.useGetAllCampaignsQuery();
    const { uuidForExport: uuid } = useSelector(selectCampaign);
    const campaign = campaigns.find((el) => el.uuid === uuid);

    const [exportType, setExportType] = useState(TypeOfExport.DOOR_TO_DOOR);

    const onExportTypeChange = (e: any) => {
        setExportType(e.target.value);
    };

    return <Drawer title={`Export for ${campaign?.name}`} subtitle='Email the export of properties for a campaign'>
        <div>
            <p className={classNames('text-sm', 'text-500', 'text-secondary', styles.title)}> Select type of export:</p>

            <Radio.Group onChange={onExportTypeChange} value={exportType}>
                <Radio value={TypeOfExport.DOOR_TO_DOOR}>Door to Door</Radio>
                <Radio value={TypeOfExport.MARKETING}>Marketing</Radio>
            </Radio.Group>
        </div>

        {exportType === TypeOfExport.DOOR_TO_DOOR && <KnockListForm />}
        {exportType === TypeOfExport.MARKETING && <MarketingForm />}
    </Drawer>;
};