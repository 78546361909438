import { useMap } from 'app/providers/MapProvider';
import mapboxgl, { Expression } from 'mapbox-gl';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCampaign } from 'store/reducers/campaignSlice';
import { Colors, LayersName, colorRule, floatToPercent, host } from '../utils';

export const usePenModelPolygon = () => {
    const { map } = useMap();
    const { uuidForMap: campaignId } = useSelector(selectCampaign);

    const popup = new mapboxgl.Popup({
        closeButton: false,
    });

    useEffect(() => {
        if (!map || !campaignId) return;

        if (map.getSource('oa')) {
            map.removeLayer(LayersName.PenModelPolygonFill);
            map.removeLayer(LayersName.PenModelPolygonLine);
            map.removeSource('oa');
        }

        // set up source
        map.addSource('oa', {
            type: 'vector',
            tiles: [
                `${host}/api/v1/campaigns/${campaignId}/properties/tiles/{z}/{x}/{y}.mvt?group=oa`,
            ],
            minzoom: 0,
            maxzoom: 15,
        });

        // set up fill color for poly
        map.addLayer({
            id: LayersName.PenModelPolygonFill,
            type: 'fill',
            source: 'oa',
            'source-layer': 'default',
            paint: {
                'fill-color': colorRule as Expression,
                'fill-opacity': 0.5
            },
            minzoom: 3,
            maxzoom: 15,
        },);

        // set up line color for poly
        map.addLayer({
            id: LayersName.PenModelPolygonLine,
            type: 'line',
            source: 'oa',
            'source-layer': 'default',
            paint: {
                'line-color': [
                    'interpolate',
                    ['exponential', 0.5],
                    ['zoom'],
                    10,
                    'transparent',
                    20,
                    Colors.Gray
                ],
                'line-opacity': 1,
                'line-width': 1,
            },
            minzoom: 3,
            maxzoom: 15,
        });

        // click on subpolygon
        map.on('click', LayersName.PenModelPolygonFill, (e) => {
            // @ts-ignore
            const props = e.features[0].properties;

            const oa_code = props?.oa_code;
            const pen_model_type = props?.pen_model_type;
            const predicated_pen = props?.predicated_pen;

            const titleHtml = `<h1>OA: <strong>${oa_code}</strong></h1>`;
            const propsHtml = `
              <h2>Pen Model Type: <strong>${pen_model_type}</strong></h2>
              <h2>Predicated Pen: <strong>${floatToPercent(predicated_pen)}</strong></h2>
            `;
            const html = `<div class='popup'>
                ${titleHtml}
                ${propsHtml}
                </div>
                `;

            popup.setLngLat(e.lngLat)
                .setHTML(html)
                .addTo(map);
        });

        map.on('mouseenter', LayersName.PenModelPolygonFill, () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', LayersName.PenModelPolygonFill, () => {
            map.getCanvas().style.cursor = '';
        });
    }, [map, campaignId]);
};