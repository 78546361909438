export const runNameFormatter = (name: string) => {
    const nameSplit = name.split('_');

    if (nameSplit.length === 1) {
        return name;
    } else {
        const timeStamp = new Date(+nameSplit[nameSplit.length - 1]);
        nameSplit.pop();
        const date = `${nameSplit.join('_')} - ${timeStamp.getDate() <= 9 ? '0' + timeStamp.getDate() : timeStamp.getDate()}.${timeStamp.getMonth() + 1 <= 9 ? '0' + (timeStamp.getMonth() + 1) : (timeStamp.getMonth() + 1)}.${timeStamp.getFullYear()}`;

        return date;
    }
};