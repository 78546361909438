import { useEffect } from 'react';
import { useAuth } from './useAuth';

export const readTokenCookie = () => {
    const cookies = document.cookie.split(' ');

    const token = cookies
        .find(el => {
            return el.includes('access_token');
        })
        ?.split('\'')[1];

    return token || null;
};
export const useSSO = () => {
    const { setToken } = useAuth();
    const token = readTokenCookie();

    useEffect(() => {
        setToken(token);
    }, []);
};
