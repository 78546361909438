import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { Modal } from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { ROUTES } from 'constants/routes';
import { NotificationType, useNotification } from 'hooks/useNotification';
import { Icons } from 'icons';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { regionsAPI } from 'services/Regions/RegionsService';
import { selectRegion } from 'store/reducers/regionSlice';
import styles from './styles.module.scss';

interface Props {
    onClose: () => void;
}

export const RemoveRegionModal: React.FC<Props> = ({ onClose }) => {
    const regionUuid = useSelector(selectRegion);
    const { param1 } = useParams();
    const navigate = useNavigate();
    const [deleteRegion, { error: deleteRegionError, isSuccess, isError }] = regionsAPI.useDeleteRegionMutation();
    console.log('regionUuid', regionUuid);
    const onSubmit = async () => {
        console.log('regionUuid', !regionUuid.uuid, !param1);
        if (!regionUuid.uuid && !param1) return;

        const region_uuid = regionUuid.uuid || param1;
        try {
            await deleteRegion(region_uuid);
            navigate(ROUTES.ROOT);
            onClose();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Error add region user:', error, deleteRegionError);
        }
    };

    useNotification({ type: NotificationType.SUCCESS, trigger: isSuccess, text: 'Region has been successfully removed!' });
    useNotification({ type: NotificationType.ERROR, trigger: isError, text: 'An error occurred while removing region. If the issue persists, contact support for assistance.' });

    return <Modal onClose={() => onClose()}>
        <ModalHeader className={styles.modalHeader}>
            <div className={styles.decoration}>
                <Icons.Attention />
            </div>
            <div className={classNames(styles.textBlock)}>
                <h2 className={classNames('text-lg', 'text-500', 'text-primary')}>Are you sure?</h2>
                <p className={classNames('text-sm', 'text-400', 'text-tertiary')}>Removed region cannot be restored!</p>
            </div>
        </ModalHeader>
        <ModalFooter>
            <Button size={ButtonSize.LG}
                palette={ButtonPalette.SECONDARY_GRAY}
                onClick={() => onClose()}
            >
                Discard
            </Button>
            <Button size={ButtonSize.LG}
                palette={ButtonPalette.PRIMARY}
                type='submit'
                onClick={onSubmit}>
                Remove region
            </Button>
        </ModalFooter>
    </Modal>;
};